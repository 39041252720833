import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import  { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/dea.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import bismillah from '../assets/img/dea/bismillah.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/fitri/covid.svg'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#293D58'
let orange = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'dea-dayat'
let inisial_co = 'Dayat'
let inisial_ce = 'Dea'

let lengkap_co = (<>Hidayatussalam Shiddiqi </>)
let lengkap_ce = (<>Dearetha Citra <br />Aulia</>)

let bapak_co = 'Bpk Darus Sutriatno '
let ibu_co = 'Ibu Royana'
let bapak_ce = "Bpk H. Ahmad Muhdlori "
let ibu_ce = "Ibu Hj. Indria Sani Adjie"

let ig_co = "dayat_id"
let ig_ce = ""

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "12/12/2020"

let modal = "https://i.ibb.co/6bC3ThB/Modal.jpg"
let openlogo = "https://i.ibb.co/KqzC6LM/Logo.png"

let gmaps = "https://goo.gl/maps/WU6p4MLjGC12xxue7"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NTNsdGpkbmxhbmVicG04YjFhcTAzZTVsc24gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["	https://i.ibb.co/sHkfv8x/DSC00518.jpg	",
    "	https://i.ibb.co/sRrqsdR/DSC00540.jpg	",
    "	https://i.ibb.co/pwftw8p/DSC00570.jpg	",
    "	https://i.ibb.co/wwtsv24/DSC00495.jpg	",
    "	https://i.ibb.co/1GptDrC/DSC00499.jpg	"
]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: '#293D58'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={gambar(openlogo)} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    {
                                        <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                            style={{ marginTop: '0' }} data-aos="fade-right">
                                            Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                    }</Item>
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        // data-aos="fade-left"
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>

                            </div>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <Col xs={10} md={4}>
                                            <img src={bismillah} className="img-fluid w-100" />
                                        </Col>
                                        <p className="fs16 text-center  px-3 py-3 w-100 text-center"
                                            style={{ color: 'white', }} data-aos="fade-left">
                                            Assalaamu'alaikum Warahmatullahi  Wabarakatuh<br /><br />
                                            Dengan memohon rahmat dan ridha Allah SWT,<br />
                                            kami bermaksud menyelenggarakan pernikahan putra-putri kami
                                        </p>
                                    </Item>
                                    {/* <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white'}}>
                                            Pernikahan {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                 */}
                                </Container>
                                {/* <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain}}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" 
                                                    style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari:</b><br />
                                                        {bapak_ce}  <br />
                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '32px', 
                                                    fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container> */}
                                <Container style={{ color: 'white' }} className="py-5">
                                    <Item>
                                        <Col md={5}>
                                            <h1 className="w-100 text-center"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: orange
                                                }} data-aos="fade-left">
                                                {lengkap_ce}
                                            </h1>
                                            <Item>
                                                <img src={logoig} className='btn p-0 my-3'
                                                    data-aos="fade-right"
                                                    onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
                                            </Item>
                                            <p className="w-100 text-center" style={{ fontSize: '16px' }}>
                                                Putri Pertama dari <br />
                                                {bapak_ce}<br />
                                                & {ibu_ce}
                                            </p>
                                        </Col>
                                        <Col md={2} >
                                            <Row className="h-100">
                                                <h1 className="w-100 text-center my-auto"
                                                    style={{ fontFamily: 'Parisienne, cursive', color: orange }} data-aos="fade-left">
                                                    &
                                            </h1>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <h1 className="w-100 text-center"
                                                data-aos="fade-right"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: orange
                                                }}>
                                                {lengkap_co}
                                            </h1>


                                            <Item>
                                                <img src={logoig} className='btn p-0 my-3'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                            </Item>
                                            <p className="w-100 text-center" data-aos="fade-left"
                                                style={{ fontSize: '16px' }}>
                                                Putra Pertama dari <br />{bapak_co}<br />
                                                & {ibu_co}
                                            </p>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container fluid className="text-center px-4 dinny py-3"
                                    style={{ color: 'white' }} >
                                    <Item>
                                        <p className="fs16" style={{}} data-aos="zoom-in">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20" style={{}} data-aos="fade-left">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: 'white', }} data-aos="fade-right">
                                            <b style={{ color: orange }}>Akad Nikah </b><br />
                                            <span className="fs16">
                                                <br />
                                            07.00 WIB - Selesai
                                             </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-sm-block"
                                            style={{
                                                color: 'white', fontSize: '72px',
                                            }} data-aos="fade-down-right">
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4"
                                            style={{ color: 'white', }} data-aos="fade-down-left">
                                            <b style={{ color: orange }}>Resepsi</b><br />
                                            <span className=" fs16 text-center ">
                                                <Item>
                                                    <ol className="pl-0 text-left" >
                                                        <li >
                                                            Tamu orang tua mempelai wanita<br />
                                                Jam 11.00 s/d 12.00 WIB
                                                </li>
                                                        <li>
                                                            Tamu orang tua mempelai pria<br />
                                                Jam 12.00 s/d 13.00 WIB
                                                </li>
                                                        <li>
                                                            Tamu mempelai pria dan wanita<br />
                                                Jam 13.00 s/d selesai
                                                </li>
                                                    </ol>
                                                </Item>
                                                <br />

                                            </span>


                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3" style={{}} data-aos="fade--left">
                                            <b>Kediaman Mempelai Wanita
                                            </b> <br />
                                            <span >
                                                Perumahan Widuri Asri Blok B3, Jl. Yos Sudarso,<br />
                                            Pemalang, Kab. Pemalang, Jawa Tengah, 52314
                    </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '20px', fontStyle: 'italic', }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            data-aos="fade-right"
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            data-aos="fade-left"

                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container id='sectiongold57'>

                                    <div className='pt-3' data-aos="fade-right">

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak'
                                                style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>
                                {/* <Container className="text-center py-5 dinny" id="dinny">
                                    <>
                                        <Item>
                                            <h1 style={{
                                                fontSize: '72px',
                                                fontFamily: "'Marck Script', cursive",
                                                color: cmain
                                            }}>
                                                Rundown
                          </h1>
                                        </Item>

                                        <Item>
                                            <Col sm={2} xs={4}>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" className="img-fluid w-100 p-2" />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <p className="cblack fs16">
                                                <b>
                                                    08.00 - 10.00
                        </b><br />
                        Akad Nikah
                      </p>
                                        </Item>
                                        <Item>
                                            <Col sm={4} className="pt-sm-3">
                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>
                                                <div className="d-block opa">
                                                    <Item>
                                                        <Col xs={3} sm={4} >
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8}>
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                              Photo Session
                            </p>
                                                        </Col>
                                                    </Item>
                                                </div>

                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>


                                            </Col>


                                            <Col sm={4} className="pt-sm-3">

                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>
                                                <div>

                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={4} xs={3}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8} >
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                             Family Photo Session
                            </p>
                                                        </Col>

                                                    </Item>
                                                </div>
                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>

                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>





                                            </Col>
                                        </Item>
                                    </>
                                </Container> */}

                                <Container className="py-3" data-aos="fade-right">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: '#B99225'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                        </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container> */}


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} data-aos="fade-right" />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3' data-aos="fade-left">

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            Jika Allah berkehendak menyatukan dua hati, maka keduanya pasti akan bersatu walaupun diantara keduanya terbentang langit dan bumi.
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3' data-aos="fade-right">
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Doa Untuk Kedua Mempelai
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Foot ig={logoig} />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

